<template>
    <div>
        <div class="tabs">
            <el-table
                ref="multipleTable"
                :data="req"
                tooltip-effect="dark"
                size="mini"
                style="width: 100%"
                @selection-change="handleSelectionChange"
            >
                <el-table-column
                    prop="fileName"
                    label="视频"
                    show-overflow-tooltip
                    width="120"
                >
                </el-table-column>
                <el-table-column prop="name" label="视频数量" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.videoCount }}
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="已发布" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.videoCount - scope.row.remainVideo }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="fileName"
                    label="链接"
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                    prop="accountCount"
                    label="账号数量"
                    width="120"
                >
                </el-table-column>
                <el-table-column prop="name" label="分发模式" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.releaseType == 1">派发任务</span>
                        <span v-if="scope.row.releaseType == 2"
                            >平台自动分发</span
                        >
                        <span v-if="scope.row.releaseType == 3">随机发送</span>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="发布状态" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.releaseState"> 发布成功 </span>
                        <span
                            v-else-if="
                                !scope.row.releaseState &&
                                scope.row.rreleaseType == 2
                            "
                            >发布失败</span
                        >
                        <span v-else>
                            剩余数量: {{ scope.row.remainVideo }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="添加时间" width="120">
                    <template slot-scope="scope">
                        {{
                            moment(scope.row.createTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                            )
                        }}
                    </template>
                </el-table-column>

                <el-table-column
                    fixed="right"
                    prop="name"
                    label="操作"
                    width="160"
                    align="right"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="danger"
                            @click="openQr(scope.row)"
                            v-if="
                                scope.row.releaseType == 3 &&
                                scope.row.remainVideo > 0
                            "
                            >发布码</el-button
                        >
                        <el-button
                            size="mini"
                            @click="checkList(scope.$index, scope.row)"
                            >查看</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <div style="margin-top: 20px">
                <el-pagination
                    small
                    layout="prev, pager, next"
                    :current-page="pagination.curr_page"
                    :page-count="pagination.pages"
                    @current-change="changePage"
                >
                </el-pagination>
            </div>
        </div>
        <el-dialog
            title="发布列表"
            :visible.sync="show_record"
            custom-class="qr_box"
            width="1000px"
        >
            <div>
                <RELEASE_RECORD :groupId="groupId" />
            </div>
        </el-dialog>
        <el-dialog
            title="扫二维码发布视频"
            :visible.sync="showQr"
            :before-close="closeQr"
            custom-class="qr_box"
            width="560px"
        >
            <QR :url="qr_url" />
        </el-dialog>
    </div>
</template>

<script>
import RELEASE_RECORD from "@/components/release/release_record";
import * as moment from "moment";
import {
    getPublishTaskList,
    getPublishUserList,
    getPublishLink,
    getPublishList,
} from "@/api/publish";
import QR from "@/components/h5/qr";
export default {
    name: "matrix_task",
    components: {
        QR,
        RELEASE_RECORD,
    },
    data() {
        return {
            // 二维码弹框
            showQr: false,
            qr_url: "",
            pagination: {
                curr_page: 1,
                pages: 1,
            },

            // 查询条件
            query: {
                current: 1, //当前页
                size: 10, //每页记录数
                pages: 0, //总页数
                asc: false,

                "orders[0].column": "create_time",
                "orders[0].asc": false,
            },
            //   任务列表数据
            req: [],
            // 发布记录
            show_record: false,
            req_list: [],
            groupId: "",
        };
    },

    mounted() {},
    created() {
        const groupId = this.$route.query.groupId;
        if (groupId) {
            this.groupId = groupId;
            this.show_record = true;
        }
        this.getPublishTask();
    },
    methods: {
        moment,
        closeQr() {
            this.showQr = false;
        },
        openQr(listData) {
            let url =
                this.$store.state.web +
                "/h5/getpublish/?groupId=" +
                listData.groupId;
            this.qr_url = url;
            this.showQr = true;
            //   let groupId = listData.groupId;
            //   let num = 1;

            //   getPublishList({ groupId, num }).then((res) => {
            //     console.log(res);
            //     if (res.code == 200) {
            //       let releaseId = res.data[0].releaseId;
            //       getPublishLink({ releaseId }).then((res) => {
            //         console.log(res);
            //         if (res.code == 200) {
            //           this.qr_url = res.msg;
            //           this.showQr = true;
            //         } else {
            //           this.$message.error("获取发布视频链接失败！");
            //         }
            //       });
            //     } else {
            //       this.$message.error("获取发布视频失败！");
            //     }
            //   });
        },
        handleSe() {},
        handleSelectionChange() {},
        getPublishTask() {
            getPublishTaskList(this.query).then((res) => {
                this.pagination.pages = res.data.pages;
                this.req = res.data.records;
            });
        },
        // 分页搜索
        changePage(page) {
            this.query.current = page;
            this.getPublishTask();
            // console.log(page);
            // console.log(this.query);
        },
        checkList(index, data) {
            this.groupId = data.groupId;
            this.show_record = true;
            // let send = {
            //     groupId: data.groupId,
            // };
            // const loading = this.$loading({
            //     lock: true,
            //     text: "Loading",
            //     spinner: "el-icon-loading",
            //     background: "rgba(0, 0, 0, 0.7)",
            // });
            // getPublishUserList(send).then((res) => {
            //     if (res.code == 200) {
            //         this.req_list = res.data.records;
            //         this.show_record = true;
            //     } else {
            //         this.$message.error("获取列表失败");
            //     }
            //     loading.close();
            // });
        },
    },
};
</script>

<style lang="less" scoped>
</style>
<template>
    <div>
        <el-table
            ref="singleTable"
            :data="list"
            highlight-current-row
            @current-change="handleCurrentChange"
            style="width: 100%"
            size="mini"
            v-loading="loading"
        >
            <el-table-column property="title" label="标题">
                <template slot-scope="scope">
                    <div class="ellipsis">{{ scope.row.title }}</div>
                </template>
            </el-table-column>
            <el-table-column
                property="authorizedUser.userNickname"
                label="发布用户"
                width="140"
            >
                <template slot-scope="scope">
                    <div class="ellipsis">
                        {{ scope.row.authorizedUser.userNickname }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="name"
                label="播放量"
                width="80px"
                align="center"
            >
                <template slot-scope="scope">
                    <div v-if="scope.row.accountVideo">
                        {{ scope.row.accountVideo.playCount }}
                    </div>
                    <div v-else>0</div>
                </template>
            </el-table-column>
            <el-table-column
                prop="name"
                label="分享数"
                align="center"
                width="80px"
            >
                <template slot-scope="scope">
                    <div v-if="scope.row.accountVideo">
                        {{ scope.row.accountVideo.shareCount }}
                    </div>
                    <div v-else>0</div>
                </template>
            </el-table-column>
            <el-table-column
                prop="name"
                label="点赞量"
                align="center"
                width="80px"
            >
                <template slot-scope="scope">
                    <div v-if="scope.row.accountVideo">
                        {{ scope.row.accountVideo.diggCount }}
                    </div>
                    <div v-else>0</div>
                </template>
            </el-table-column>
            <el-table-column
                prop="name"
                align="center"
                label="评论数"
                width="80px"
            >
                <template slot-scope="scope">
                    <div v-if="scope.row.accountVideo">
                        {{ scope.row.accountVideo.commentCount }}
                    </div>
                    <div v-else>0</div>
                </template>
            </el-table-column>
            <el-table-column property="releaseState" label="状态" width="80">
                <template slot-scope="scope">
                    <span
                        style="color: #67c23a"
                        v-if="scope.row.releaseState == 1"
                    >
                        已发布
                    </span>
                    <span
                        style="color: #f56c6c"
                        v-else-if="scope.row.releaseState == 2"
                        >锁定</span
                    >
                    <span
                        style="color: #f56c6c"
                        v-else-if="scope.row.releaseState == 3"
                        >发布失败</span
                    >
                    <span
                        style="color: #f56c6c"
                        v-else-if="scope.row.releaseState == 4"
                        >用户未绑定手机</span
                    >
                    <span
                        style="color: #409eff"
                        v-else-if="scope.row.releaseState == 5"
                        >发布中...</span
                    >
                    <span
                        style="color: #409eff"
                        v-else-if="scope.row.releaseState == 6"
                        >定时发布</span
                    >
                    <span
                        style="color: #f56c6c"
                        v-else-if="scope.row.releaseState == 7"
                        >撤销发布</span
                    >
                    <span style="color: #909399" v-else> 未发布 </span>
                </template>
            </el-table-column>

            <el-table-column
                fixed="right"
                prop="name"
                label="操作"
                width="60"
                align="right"
            >
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        size="mini"
                        @click="openVideo(scope.row)"
                        >查看视频</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <div class="page_box">
            <el-pagination
                small
                background
                layout="prev, pager, next"
                :total="total"
                @current-change="current_change"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { getPublishUserList } from "@/api/publish";
export default {
    props: ["groupId"],
    data() {
        return {
            loading: false,
            total: 0,
            list: [],
            where: {
                groupId: this.groupId,
                current: 1,
                size: 10,
            },
        };
    },
    watch: {
        groupId(val) {
            this.where.groupId = val;
            this.getList();
        },
    },
    mounted() {
        this.getList();
    },
    methods: {
        async getList() {
            this.loading = true;
            let where = this.where;
            let res = await getPublishUserList(where);
            if (res.code == 200) {
                this.list = res.data.records;
                this.total = res.data.total;
            }
            this.loading = false;
        },
        openVideo(res) {
            window.open(res.accountVideo.videoUrl);
        },
        current_change(current) {
            this.where.current = current;
            this.getList();
        },
    },
};
</script>

<style lang="less" scoped>
</style>
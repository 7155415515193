<template>
    <div class="qr-content">
        <div class="qr-row">
            <div class="qr-info">
                <div class="qr-box" id="qrcode" ref="qrCodeDiv">
                    <vueQr :text="url" :margin="0" :width="100" />
                </div>
                <div class="qr-info-right">
                    <ul>
                        <li class="qr-title">
                            抖音APP二维码，使用抖音APP扫码发布视频无风险提示
                        </li>
                        <li class="qr-tis">
                            注：在使用浏览器扫当前码时，需要登录抖音账号才可以发布视频
                        </li>
                    </ul>
                    <div>
                        <el-button size="small" @click="copy"
                            >复制链接</el-button
                        >
                        <el-button
                            size="small"
                            @click="downloadCode"
                            type="primary"
                            >保存图片</el-button
                        >
                    </div>
                    <div id="downloadImg"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vueQr from "vue-qr";

export default {
    name: "",
    components: {
        vueQr,
    },
    props: ["url"],
    data() {
        return {};
    },

    mounted() {},

    methods: {
        copy() {
            this.$copyText(this.url).then(
                (e) => {
                    console.log(e);
                    this.$message.success("链接复制成功~快去分享吧!");
                },
                (e) => {
                    console.log(e);
                    console.log("复制成功");
                }
            );
        },
        downloadCode() {
            let img = document
                .getElementById("qrcode")
                .getElementsByTagName("img")[0];
            //   console.log(img.src);
            var alink = document.createElement("a");

            alink.href = img.src;

            alink.download = "发布二维码";
            alink.click();
            this.$message.warning("鼠标右键图标保存二维码");
        },
    },
};
</script>

<style lang="less" scoped>
.qr-box {
    width: 100px;
    height: 100px;
    background: url("~@/assets/images/publist/qr_box.png");
    background-size: 100% 100%;
    padding: 10px;
}
.qr-info {
    display: flex;
    .qr-info-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 4px 0;
        padding-left: 20px;
        .qr-title {
            color: #060505;
        }
        .qr-tis {
            font-size: 12px;
            margin-top: 10px;
        }
    }
}
</style>